<template>
  <div>
    <a href="mailto:billedhugger@steffenlund.dk">billedhugger@steffenlund.dk</a>
    <p> tlf: 47522099 / 40892099 - Landerslevvej 91 - 3630 Jægerspris</p>
  </div>
</template>

<script>

export default {
  name: "Contact"
}
</script>


<style scoped>

</style>